import { Controller } from "stimulus"
import { throws } from "assert"

export default class extends Controller {
  static targets = ["offer", "products"]

  addPackage(event) {
    this.offerTargets.map(offer => {
      if (event.currentTarget.dataset.id === offer.dataset.id) {
        const value  = parseInt(offer.value)
        const limit = parseInt(offer.dataset.limit)
        if (value < limit) {
          offer.value = parseInt(offer.value) + 1
          this.getProducts()
        }
      }
    })
  }

  getProducts() {
    const orders = this.offerTargets.filter(offer => {
      return offer.value > 0
    })
    const products = orders.map(order => {
      return `${order.value} x ${order.dataset.title}`
    })
    this.productsTarget.innerHTML = products.join(', ')
  }
}
